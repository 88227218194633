/* global */
import "../../css/app/components/home.scss";

class Home {
    constructor() {
        // import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
        //     $('.slick-carousel').slick();
        // });
        this.initStepAnimation();
        console.log('Home initialised');
    }

    initStepAnimation() {
        const items = document.querySelectorAll('.step-item');
        let currentIndex = 0;
        let loopCompleted = false;

        function activateNextItem() {
            if ((currentIndex + 1) == 4) {
                items[currentIndex].classList.add('active', 'border-none');
            } else if ((currentIndex + 1) == 8) {
                items[currentIndex].classList.add('active', 'border-none');
            } else {
                items[currentIndex].classList.add('active');
            }
            //console.log(`Item ${currentIndex + 1} activated`);
            currentIndex++;
            if (currentIndex === items.length) {
                loopCompleted = true;
            }
        }

        function removeActiveClass() {
            items.forEach(item => {
                item.classList.remove('active');
            });
            //console.log('Active class removed');
        }

        setInterval(() => {
            activateNextItem();
            if (loopCompleted) {
                setTimeout(() => {
                    removeActiveClass();
                    currentIndex = 0;
                    loopCompleted = false;
                }, 1200); // Remove active class after 1.2 second (1200ms)
            }
        }, 1200); // Activate each item every 1.2 second (1200ms)
    }

}

new Home;